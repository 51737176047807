<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="mx-0"
        :disabled="disabled"
        x-small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h3">{{ $t("dropoff_location") }}</span>
      </v-card-title>

      <v-card-text>
        <gmap-map
          :zoom="14"
          :center="position"
          style="width: 100%; height: 350px"
        >
          <gmap-marker
            :position="position"
            :draggable="true"
            @drag="updateCoordinates"
          />
          <!-- @click="center = position" -->
        </gmap-map>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!coordinates"
          @click="saveLocation"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
export default {
  // components: {
  //   LMap,
  //   LTileLayer,
  //   LMarker,
  // },
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    startPnt: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isVisible: false,

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=en",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 14,
      center: [24.87847, 67.0645625],
      markerLatLng: [24.87847, 67.0645625],
      //
      position: {
        lat: 24.87847,
        lng: 67.0645625,
      },
      coordinates: null,
    };
  },
  computed: {},
  async mounted() {},
  methods: {
    updateCoordinates(location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    closeForm() {
      this.isVisible = false;
    },
    saveLocation() {
      this.item.isLocation = true;
      this.item.DropLatitude = this.coordinates.lat;
      this.item.DropLongitude = this.coordinates.lng;
      // this.item.PickupLatitude = this.coordinates.lat;
      // this.item.PickupLongitude = this.coordinates.lng;
      this.item.PickupLatitude = this.startPnt.lat;
      this.item.PickupLongitude = this.startPnt.long;

      this.$emit("getLocation", this.coordinates);
      const params = { ...this.coordinates };
      params.isLocation = true;
      this.$emit("input", params);

      this.isVisible = false;
    },
  },
};
</script>
<style lang="sass" scoped></style>
